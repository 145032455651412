import React from 'react';
import styled from 'styled-components';
import logo from '../../images/logo-juancho-te-presta.png';
import facebook from '../../images/icons/facebook.png';
import instagram from '../../images/icons/instagram.png';
import twitter from '../../images/icons/twitter.png';
import tikTok from '../../images/icons/tikTok.png';
import youtube from '../../images/icons/youtube.png';
import linkedin from '../../images/icons/linkedin.png';
import threads from '../../images/icons/threads.png';
import { breakpointLarge } from '../../styles/breakpoints';
import ContentContainer from '../UI/ContentContainer';

const FooterStyled = styled.footer`
  position: relative;
  background: ${(props) => props.theme.gray};
  height: 180px;
  margin-top: 60px;
  @media (min-width: ${breakpointLarge}) {
    height: 240px;
    margin-top: 80px;
  }
`;

const ContentContainerStyled = styled(ContentContainer)`
  height: 90px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3.75rem;
  bottom: 0;
  left: 0;
  @media (min-width: ${breakpointLarge}) {
    height: 170px;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: -28px;
  width: 80px;
  height: 80px;
  margin-top: -0.75rem;
  @media (min-width: ${breakpointLarge}) {
    top: -42px;
    width: 110px;
    height: 110px;
  }
`;
const SocialNetworks = styled.div`
  margin-top: 1rem;
  a {
    margin: 0.25rem;
  }
  img {
    width: 1.3rem;
    height: 1.3rem;
    @media (min-width: ${breakpointLarge}) {
      width: 2.3rem;
      height: 2.3rem;
    }
  }
`;
const ContentLogo2 = styled(ContentContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45px;
  @media (min-width: ${breakpointLarge}) {
    height: 80px;
  }
`;
const RelevantInformation = styled.div`
  height: auto;
  color: white;
  text-align: center;
  p {
    font-size: 8px;
    line-height: 0.3em;
    @media (min-width: ${breakpointLarge}) {
      font-size: 12px;
      line-height: 0.5em;
    }
  }
`;

const Footer = class extends React.Component {
  render() {
    return (
      <FooterStyled>
        <ContentContainerStyled>
          <RelevantInformation>
            <ContentLogo2>
              <Logo src={logo} alt="Juancho te presta" />
            </ContentLogo2>
            <SocialNetworks>
              <a title="Facebook" href="https://jtp.lat/LcE">
                <img src={facebook} alt="Facebook" />
              </a>
              <a title="Instagram" href="https://jtp.lat/OvO">
                <img src={instagram} alt="Instagram" />
              </a>
              <a title="twitter" href="https://jtp.lat/yQd">
                <img src={twitter} alt="twitter" />
              </a>
              <a title="tikTok" href="https://jtp.lat/Dmj">
                <img src={tikTok} alt="tikTok" />
              </a>
              <a title="youtube" href="https://jtp.lat/BbX">
                <img src={youtube} alt="youtube" />
              </a>
              <a title="linkedin" href="https://jtp.lat/Zbl">
                <img src={linkedin} alt="linkedin" />
              </a>
              <a title="Threads" href="https://jtp.lat/DWI">
                <img src={threads} alt="Threads" />
              </a>
            </SocialNetworks>
            <p>Tasa de interés: 25.99% EA.</p>
            <p>Tasa máxima de interés vigente: 26.39% EA. </p>
            <p>
              Dirección física: Juancho Te Presta SAS. Km 2, vía El Tambo; Andes
              BPO. La Ceja, Antioquia.
            </p>
            <p>Línea gratuita nacional: 018000413811</p>
          </RelevantInformation>
        </ContentContainerStyled>
      </FooterStyled>
    );
  }
};

export default Footer;
