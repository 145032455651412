/* eslint react-hooks/exhaustive-deps: 0 */
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';
import Checkbox from '../../components/Form/Checkbox';
import Form from '../../components/Form/Form';
import FormGroup from '../../components/Form/FormGroup';
import InputNumber from '../../components/Form/InputNumber';
import Label from '../../components/Form/Label';
import Column from '../../components/Grid/Column';
import Row from '../../components/Grid/Row';
import AvalInfoIndicator from '../../components/Indicators/AvalInfoIndicator';
import ElectronicInformation from '../../components/Indicators/ElectronicSignature';
import Indicator from '../../components/Indicators/Indicator';
import InteresRate from '../../components/Indicators/InteresRate';
import LegalInformation from '../../components/Indicators/LegalInfo';
import QuoteInfoIndicator from '../../components/Indicators/QuoteInfoIndicator';
import Loan from '../../components/LoanSimulator';
import Button from '../../components/UI/Button';
import ButtonContainer from '../../components/UI/ButtonContainer';
import GeneralContent from '../../components/UI/GeneralContent';
import Wizard, { WizardProps } from '../../components/Wizard';
import moneyFormat from '../../helpers/moneyFormat';
import range from '../../helpers/range';
import useSimulatorCalc from '../../hooks/useSimulatorCalc';
import ArrowL from '../../images/icons/arrow-left';
import Market from '../../images/icons/market.js';
import { JTPState } from '../../store';
import jobOptionsSelector from '../../store/selectors/jobOptionsSelector';
import styled from '../../styles/styled-components';

const IconContainer = styled.div`
  position: relative;
  align-content: Center;
  justify-items: center;
  height: 5rem;
  width: 5rem;
  > h3 {
    top: 4%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;

const WizardTrackerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 1rem;
  position: relative;
  flex-grow: 1;
  &:before {
    content: '';
    display: block;
    width: calc(100% - 5px);
    height: 2px;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
`;

interface MarkProps {
  clickable?: boolean;
  active: boolean;
  finished: boolean;
}

const WizardMarkStyled = styled.div<MarkProps>`
  height: 2rem;
  width: 3rem;
  margin: 0 2px;
  border-radius: 50%;
  display: flex;
  align-self: center;
  color: ${(props) =>
    props.finished
      ? props.active
        ? props.theme.gray202
        : props.theme.gray202
      : props.theme.blueDark};
  z-index: 1;
  transition: transform 100ms;
  .fill-color {
    fill: ${(props) => props.theme.blueDark};
    transition: all 0.5s ease;
  }
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  justify-content: center;
  align-items: center;
  .fill-color {
    fill: ${(props) =>
    props.finished
      ? props.active
        ? props.theme.blueDark
        : props.theme.blueDark
      : props.theme.gray202};
    z-index: 1;
    transition: transform 100ms;
  }
  :hover {
    transform: ${(props) => (props.clickable ? 'scale(1.1)' : 'none')};
  }
`;

const WizardTracker: React.FC<{ wizard: WizardProps }> = ({ wizard }) => {
  return (
    <Row>
      <WizardMarkStyled
        clickable
        active
        finished
        onClick={() => wizard.decrease()}
      >
        {<ArrowL />}
      </WizardMarkStyled>
      <WizardTrackerStyled>
        {range(1, wizard.steps).map((i) => (
          <WizardMarkStyled
            key={i}
            active={wizard.step === i}
            finished={wizard.step >= i}
          >
            <IconContainer>
              <Market />
              <h3>{i}</h3>
            </IconContainer>
          </WizardMarkStyled>
        ))}
      </WizardTrackerStyled>
    </Row>
  );
};

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  align-content: center;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  input[name='income'] {
    text-align: center;
    font-size: 1.5rem;
  }
  p {
    color: ${(props) => props.theme.blueDark};
    font-size: 18px;
    font-weight: bold;
  }
`;

const SimulatorResults = styled.div`
  
  display: flex;
  flex-direction:column;
  align-items: stretch;
  text-align: left;
  margin: 24px 0 16px;
  p {
    color: ${(props) => props.theme.blueDark};
    font-size: 16px;
    font-weight: 400;
    padding:0;
    margin:0;
  }
`;

const Interes = styled.div`
position: relative;
margin-bottom:18px;

`
const Rate = styled.div`
position: absolute;
top:-16px;

`

interface Props extends RouteComponentProps { }

const SimulateView: React.FC<Props> = ({ history }) => {
  const simulator = useSelector((state: JTPState) => state.simulator);
  const jobOptions = useSelector(jobOptionsSelector);

  const [simData, simActions] = useSimulatorCalc();
  const [jobType, setJobType] = useState<string>('');
  const [income, setIncome] = useState(0);
  const [minIncome, setMinIncome] = useState(0);

  const wizard = Wizard.useWizard(2);

  useEffect(() => {
    if (wizard.step === 0) {
      window.location.replace(`${process.env.REACT_APP_CMS}`);
    }
  }, [wizard.step]);

  useEffect(() => {
    simActions.setMonthlySalary(income);
    simActions.setPaymentPeriodicity('Quincenal');
    simActions.setJobType(jobType as any);
  }, [jobType, income]);

  useEffect(() => {
    simulator.options.forEach(({ workingStatus }) => {
      if (workingStatus === 'Empleado') {
        setJobType(workingStatus);
        setMinIncome(jobOptions[workingStatus]?.minIncome ?? 0);
      }
    });
  }, []);

  const handleIncomeSubmit = useCallback((values: any) => {
    setIncome(values.income);
    wizard.setStep(2);
  }, []);

  return (
    <Column fluid>
      <GeneralContent>
        <h2>Simula tu crédito</h2>
        <>
          <WizardTracker wizard={wizard} />

          <Wizard.Steps wizard={wizard}>
            <Wizard.Step step={1}>
              <Content>
                <Formik
                  initialValues={{ income: 0 }}
                  onSubmit={handleIncomeSubmit}
                  validateOnChange
                  validationSchema={yup.object({
                    income: yup
                      .number()
                      .typeError('Unicamente caracteres númericos')
                      .min(
                        minIncome,
                        `El salario debe ser igual o superior a ${moneyFormat(
                          minIncome
                        )}`
                      )
                      .required(''),
                  })}
                >
                  <Form>
                    <Content>
                      <GeneralContent>
                        <FormGroup>
                          <Label htmlFor="income">Ingresa tu salario</Label>
                          <InputNumber
                            name="income"
                            placeholder={`Valor mínimo ${moneyFormat(
                              minIncome
                            )}`}
                            icon="dollar-sign"
                            help={`Mínimo ${moneyFormat(minIncome)}`}
                          />
                        </FormGroup>
                      </GeneralContent>
                      <ButtonContainer>
                        <Button type="submit">Calcular</Button>
                      </ButtonContainer>
                    </Content>
                  </Form>
                </Formik>
              </Content>
            </Wizard.Step>

            <Wizard.Step step={2}>
              <Content>
                <Loan.Simulator
                  initialAmount={simData.initialAmount}
                  initialTerm={simData.initialTerm}
                  minAmount={simData.minAmount}
                  maxAmount={simData.maxAmount}
                  terms={simData.terms}
                  // interestRate={simData.interestRate}
                  onChange={simActions.setValues}
                >
                  <Loan.TermOptions />
                  <Loan.AmountSlider step={50000} />
                  <SimulatorResults>
                    <Loan.Label
                    
                      label="Total desembolsado:"
                      calculate={() => moneyFormat(simData.totalDisbursed)}
                      component={AvalInfoIndicator}
                    />
                    <Loan.Label
                      label="Cuota mensual:"
                      calculate={() => moneyFormat(simData.monthlyQuote)}
                      component={QuoteInfoIndicator}
                    />
                    <Loan.Label
                      label="Amortización crédito:"
                      calculate={() => moneyFormat(simData.monthlyRedemption)}
                      component={LegalInformation}
                    />
                    <Loan.Label
                      label="Servicio de Firma Electrónica (opcional):"
                      calculate={() => moneyFormat(simData.monthlyVAT)}
                      component={ElectronicInformation}
                    />
                    <Loan.Label
                      label="Tasa de interés:"
                      component={InteresRate}
                    />
                    <Interes>
                      <Rate>
                      25.99% EA
                      </Rate>
                    </Interes>
                    <Loan.Label
                      label="Aval variable de terceros (opcional):"
                      calculate={() => moneyFormat(simData.monthlyAval)}
                      component={Indicator}
                    />
                    
                    <br />
                    <Loan.Label
                      label="Fecha de primer pago:"
                      calculate={() => simData.firstDatePayment}
                      component={Indicator}
                    />
                    <div>
                      <Checkbox
                        name="woman"
                        checked={simData.gender === 'F'}
                        onChange={(e) =>
                          simActions.setGender(e.target.checked ? 'F' : 'M')
                        }
                      >
                        ¿Eres mujer?
                      </Checkbox>
                    </div>
                  </SimulatorResults>
                </Loan.Simulator>
                <Column center>
                  <ButtonContainer vertical>
                    <Button as={NavLink} to="/signup">
                      Solicítalo ¡YA!
                    </Button>
                    <NavLink to="/login">Iniciar sesión</NavLink>
                  </ButtonContainer>
                </Column>
              </Content>
            </Wizard.Step>
          </Wizard.Steps>
        </>
      </GeneralContent>
    </Column>
  );
};

export default SimulateView;
