import React from 'react';
import NavLink from '../components/UI/NavLink';
import { validateEmail, validatePhone } from '../trackers/zipps';
import ApiError from '../types/ApiError';
import { FormFieldsError } from './FormFieldsError';
import serviceBuilder from './serviceBuilder';

export interface Params {
  email: string;
  phone: string;
  id: string;
  givenname: string;
  lastname: string;
  surname: string;
}

export interface Result {
  status: boolean;
}

export const url = 'users/fields';

export let nm = false;
export let em = false;
export let ph = false;
export let wp = false;

const service = serviceBuilder<Params, Result>('post', {
  url,
  auth: false,
});

function removeTildes(str: string) {
  return str
    .toUpperCase()
    .trim()
    .replace(/\s\s/g, ' ')
    .replace(/Á/g, 'A')
    .replace(/É/g, 'E')
    .replace(/Í/g, 'I')
    .replace(/Ó/g, 'O')
    .replace(/Ú/g, 'U')
    .replace(/Ñ/g, 'N');
}

export default async ({
  id,
  phone,
  email,
  givenname,
  lastname,
  surname,
}: Params) => {
  try {
    // const values = {
    //   id: id,
    //   idType: 'CC',
    //   givenname: removeTildes(givenname),
    //   lastname: removeTildes(lastname), 
    //   surname: removeTildes(surname),
    // }
    // const name = await validateName(values)
    const phoneValidate = await validatePhone(`57${phone}`)
    const emailValidate = await validateEmail(email)
    // console.log("phone Validate ==>",phoneValidate)
    // console.log("email Validate ==>",emailValidate)
    // console.log("name Validate ==>",name)
    
    // if (name.status === "UNMATCH") {
    //   nm = true
    //   throw new FormFieldsError({
    //     lastname: ' ',
    //     givenname: ' ',
    //     surname: surname ? ' ' : '',
    //     'form-error': (
    //       <p>
    //         Verifica tus <b>Nombres</b> y <b>Apellidos</b>, ingrésalos como se
    //         encuentran en tu <b>documento de identidad</b>
    //       </p>
    //     ),
    //   });
    // } else {
    //   nm = false
    // }
    if(emailValidate.status=== "Invalid"){
      em = true
      throw new FormFieldsError({
        email: ' ',
        confirmEmail:' ',
        'form-error': (
          <p>
            Verifica tu <b>Email</b> , Usa una cuenta de correo válida
          </p>
        ),
      });
    }else{
      em = false
    }
    if(phoneValidate.status=== "Invalid"){
      ph = true
      throw new FormFieldsError({
        phone: ' ',
        'form-error': (
          <p>
            Verifica tu <b>Telefono</b> , Usa un número de telefono válida
          </p>
        ),
      });
    }
    else{
      ph=false
      if(phoneValidate.wp === false){
        wp = true
        throw new FormFieldsError({
          phone: ' ',
          'form-error': (
            <p>
              Verifica tu <b>Telefono</b> , Usa un número de telefono registrado en WhatsApp 
            </p>
          ),
        });
      }
      else{
        wp=false
        await service({
          id: id.replace(/\s/, ''),
          phone: phone.replace(/\s/, ''),
          email: email.replace(/\s/, ''),
          givenname: removeTildes(givenname),
          lastname: removeTildes(lastname),
          surname: removeTildes(surname),
        });
      }
    }
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.code === 403) {
        throw new FormFieldsError({
          'form-error': (
            <p>
              Con alguno de los datos que ingresaste ya existe un usuario
              registrado,{' '}
              <NavLink to="/login">por favor ingresa a tu cuenta</NavLink>
            </p>
          ),
        });
      }
      if (e.code === 422) {
        throw new FormFieldsError({
          lastname: ' ',
          givenname: ' ',
          surname: surname ? ' ' : '',
          'form-error': (
            <p>
              Verifica tus <b>Nombres</b> y <b>Apellidos</b>, ingrésalos como se
              encuentran en tu <b>documento de identidad</b>
            </p>
          ),
        });
      }else {
        throw new FormFieldsError({
          'form-error':
            'Ocurrio un error al validar tu información, intenta más tarde',
        });
      }
    }
    // if ( nm === true) {
    //   console.log('[ZIPPS validate name]')
    //   throw new FormFieldsError({
    //     lastname: ' ',
    //     givenname: ' ',
    //     surname: surname ? ' ' : '',
    //     'form-error': (
    //       <p>
    //         Verifica tus <b>Nombres</b> y <b>Apellidos</b>, ingrésalos como se
    //         encuentran en tu <b>documento de identidad</b>
    //       </p>
    //     ),
    //   });
    // } 
    else if ( em === true) {
      console.log('[ZIPPS validate email]')
      throw new FormFieldsError({
        email: ' ',
        confirmEmail:' ',
        'form-error': (
          <p>
          Verifica tu <b>Email</b> , Usa una cuenta de correo válida
        </p>
        ),
      });
    }
    else if ( ph === true) {
      console.log('[ZIPPS validate phone]')
      throw new FormFieldsError({
        phone: ' ',
        'form-error': (
          <p>
            Verifica tu <b>Telefono</b> , Usa un número de telefono válido
          </p>
        ),
      });
    }else if(wp=== true){
      throw new FormFieldsError({
        phone: ' ',
        'form-error': (
          <p>
            Verifica tu <b>Telefono</b> , Usa un número de telefono con WhatsApp
          </p>
        ),
      });
    }else {
      throw new FormFieldsError({
        'form-error':
          'Ocurrio un error al validar tu información, intenta más tarde',
      });
    }
  }

};